import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/StripePaymentForm.css";

import { HttpLoader } from "./components/HttpLoader";
import { api } from "./configs/axiosConfigs";

//alphabetically
import EstimateConfirm from "./routes/EstimateConfirm";
import InvoiceConfirm from "./routes/InvoiceConfirm";
import PreInvoiceConfirm from "./routes/PreInvoiceConfirm";

import Completion from "./routes/Completion";
import Revision from "./routes/Revision";
import Landing from "./routes/Landing";

export default function App() {
  const [requestCounter, setRequestCounter] = useState(0);

  // http interceptors
  useEffect(() => {
    // loader
    api.interceptors.request.use(loaderRequestInterceptor);
    api.interceptors.response.use(
      loaderResponseInterceptor,
      loaderResponseInterceptor
    );
  }, []);

  const loaderRequestInterceptor = function (requestConfig) {
    setRequestCounter((val) => val + 1);
    console.log(`RequestCounter: ${requestCounter}`);
    return requestConfig;
  };

  const loaderResponseInterceptor = function (axios) {
    setRequestCounter((val) => (val - 1 < 0 ? 0 : val - 1));
    console.log(`RequestCounter: ${requestCounter}`);
    console.log(`---------->`, axios);
    switch (axios.response?.status) {
      case 400:
        toast.warn(axios.response.data);
        break;
      case 500:
        toast.error("Attenzione, si è verificato un errore");
        break;
      default:
        break;
    }
    return axios;
  };

  return (
    <>
      <HttpLoader visible={requestCounter > 0} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <>
        <Router>
          <Routes>
            {/* Non-Protected Routes */}
            <Route
              path="/:businessId/e/:estimateId/:linkId"
              element={<EstimateConfirm />}
            ></Route>
            <Route
              path="/:businessId/i/:invoiceId/:linkId"
              element={<InvoiceConfirm />}
            ></Route>
            <Route
              path="/:businessId/p/:preInvoiceId/:linkId"
              element={<PreInvoiceConfirm />}
            ></Route>
            <Route path="/completion" element={<Completion />}></Route>
            <Route path="/revision" element={<Revision />}></Route>
            <Route path="*" element={<Landing />} />
          </Routes>
        </Router>
      </>
    </>
  );
}
