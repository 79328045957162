// SelectProvince.js
import React, { useState, useEffect } from "react";
import Select from "react-select";

import provinces from "../../assets/provinces.json";

const SelectProvince = ({
  id,
  name,
  className,
  value,
  onChange,
  onBlur,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    let selectedProvince = provinces.find(
      (province) => province.sigla === value
    );
    setSelectedValue(
      !!selectedProvince && {
        label: selectedProvince.nome,
        value: selectedProvince.sigla,
      }
    );
  }, [value]);

  const handleChange = (option) => {
    setSelectedValue(option);
    onChange(option?.value);
  };

  return (
    <Select
      id={id}
      name={name}
      className={className}
      placeholder="-- Seleziona --"
      options={provinces.map((province) => ({
        label: province.nome,
        value: province.sigla,
      }))}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      value={selectedValue}
      isDisabled={disabled}
      onChange={handleChange}
      onBlur={onBlur}
      isClearable={true}
      isSearchable={true}
    />
  );
};

export default SelectProvince;
