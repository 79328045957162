import { api } from "../configs/axiosConfigs";

export const TransactionsApi = {
  apiName: "TransactionsApi",
  baseUrl: "/webapp/businesses",

  startAsync: async function (businessId, transactionId, payload) {
    const fnName = "startAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/transactions/${transactionId}/start`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
