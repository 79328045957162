import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

export default function StripeCheckoutForm({ type }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);

  const onConfirm = async () => {
    // e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let options = {
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
    };

    let paymentElement = elements.getElement(PaymentElement);
    options.elements = elements;

    let result;
    switch (type) {
      case "collect":
        // Qui esegui il processo di 'collect' per una nuova carta
        result = await stripe.confirmSetup(options);
        break;
      default:
        result = await stripe.confirmPayment(options);
        break;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      result.error?.type === "card_error" ||
      result.error?.type === "validation_error"
    ) {
      setMessage(result.error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
  };

  return (
    <>
      <img
        loading="lazy"
        src="/assets/images/logos/logo-holo.png"
        alt="Logo Holo"
        height="40"
        className="mx-1 rounded"
      />
      <PaymentElement />
      <button
        disabled={!stripe || !elements}
        id="submit"
        className="stripe-button"
        onClick={onConfirm}
      >
        <span id="button-text">Conferma</span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </>
  );
}
