import { api } from "../configs/axiosConfigs";

export const PreInvoicesApi = {
  apiName: "PreInvoicesApi",
  baseUrl: "/webapp/businesses",

  async acceptAsync(businessId, preInvoiceId, linkId, payload) {
    const fnName = "acceptAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      preInvoiceId,
      linkId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/preInvoices/${preInvoiceId}/publiclinks/${linkId}/accept`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  async getByPublicLinkAsync(businessId, preInvoiceId, linkId) {
    const fnName = "getByPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      preInvoiceId,
      linkId
    );

    const response = await api.get(
      `${this.baseUrl}/${businessId}/preInvoices/${preInvoiceId}/publiclinks/${linkId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
