export default function EstimateInvoiceTypeItem({
  index,
  invoiceType,
  checked,
  onClick,
}) {
  const getInvoiceTypeLabel = (value) => {
    switch (value) {
      case 0:
        return "Fattura non necessaria";
      case 1:
        return "Fattura totale (solo per pagamento al 100%)";
      default:
        return "Fattura di anticipo e consegna";
    }
  };

  return (
    <div className="d-flex align-items-center form-check">
      <input
        id={`invoiceType-${index}`}
        name={`invoiceType-${index}`}
        className="form-check-input"
        type="radio"
        checked={checked}
        onClick={onClick}
      />
      <label htmlFor={`invoiceType-${index}`} className="ms-2 form-check-label">
        {getInvoiceTypeLabel(invoiceType)}
      </label>
    </div>
  );
}
