import { api } from "../configs/axiosConfigs";

export const InvoicesApi = {
  apiName: "InvoicesApi",
  baseUrl: "/webapp/businesses",

  async acceptAsync(businessId, invoiceId, linkId, payload) {
    const fnName = "acceptAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId,
      linkId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/invoices/${invoiceId}/publiclinks/${linkId}/accept`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  async getByPublicLinkAsync(businessId, invoiceId, linkId) {
    const fnName = "getByPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId,
      linkId
    );

    const response = await api.get(
      `${this.baseUrl}/${businessId}/invoices/${invoiceId}/publiclinks/${linkId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
