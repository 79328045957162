import { _BaseUtil } from "./_BaseUtil";

import "../extensions/dateExtensions"; // Import the dateExtensions.js file

export const EstimatesUtil = {
  getModelTemplate: async function (businessId) {
    let today = new Date();

    let estimate = {
      code: await this.getNextAvailableCodeAsync(
        businessId,
        today.getFullYear()
      ),
      revisionNumber: 1,
      contactId: null,
      allowPublicPageEdit: true,
      subject: "",
      description: "",
      closingDays: 60,
      tags: "",
      currencyType: 1, //1. Fiat, 2. Crypto
      currency: "EUR",
      customerNotes: "",
      status: 1, //1. Draft, 2. Waiting, 3. Accepted, 4. Declined, 5. Reviewed
      revisedEstimateId: "", // To fill only for revise
    };

    estimate.date = today;
    estimate.expirationDate = today.addDays(5);
    estimate.closingDate = today.addDays(estimate.closingDays);

    let model = {
      estimate: estimate,
      rows: [],
      conditions: [],
      links: [],
    };

    return model;
  },

  countByContactId: function (estimates, contactId) {
    return _BaseUtil.countBy(estimates, "contactId", contactId);
  },

  getRowByService: function (service) {
    if (!service) return {};
    return {
      serviceId: service.rowKey,
      description: service.description,
      quantity: service.defaultQuantity || 1,
      taxPercentage: service.taxPercentage || 0,
      amount: service.defaultAmount,
    };
  },

  getStatusDescription: function (status) {
    switch (status) {
      case 1:
        return "Bozza";
      case 2:
        return "Attesa pagamento";
      case 3:
        return "Pagato";
      case 4:
        return "Rifiutato";
      case 5:
        return "Revisionato";
      default:
        break;
    }
    return "";
  },

  getStatusBadge: function (status) {
    switch (status) {
      case 1:
        return "bg-info";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-success";
      case 4:
        return "bg-danger";
      case 5:
        return "bg-info";
      default:
        break;
    }
    return "";
  },

  getServicesTotalAmount: function (services, type) {
    console.log("getServicesTotalAmount", services);
    return services && services.length
      ? services
          .filter((x) => x.serviceType === type)
          .reduce((acc, row) => Number(acc) + Number(row.amount), 0)
      : 0;
  },

  mapToEstimateCondition: function (estimateId, condition) {
    if (!condition) return {};

    let estimateCondition = { ...condition };
    estimateCondition.estimateId = estimateId;

    console.log("EstimateCondition", estimateCondition);

    return estimateCondition;
  },

  getNextMonthFirstDayDate: function () {
    const date = new Date();

    date.setMonth(date.getMonth() + 1);
    date.setDate(1);

    return date;
  },
};
