import StripeCheckoutForm from "./StripeCheckoutForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

function StripePayment({ amount, onSubmit }) {
  const [clientSecret, setClientSecret] = useState(null);
  const [customerSessionClientSecret, setCustomerSessionClientSecret] =
    useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);

  const startPayment = async () => {
    const { stripeAccount, clientSecret, customerSessionClientSecret } =
      await onSubmit(); // Recupera il client secret dal backend
    setStripeAccount(stripeAccount);
    setClientSecret(clientSecret);
    setCustomerSessionClientSecret(customerSessionClientSecret);
  };

  return (
    <>
      {!clientSecret && (
        <>
          <div className="row">
            <div className="col-12 col-md-6">
              <button
                onClick={() => {
                  startPayment();
                }}
                className="btn btn-info"
              >
                {"Paga con Stripe"}
              </button>
            </div>
          </div>
        </>
      )}
      {!!clientSecret && !!stripeAccount && (
        <>
          <Elements
            stripe={loadStripe(process.env.REACT_APP_STRIPE_PK, {
              betas: ["elements_saved_payment_methods_beta_1"],
              stripeAccount,
            })}
            options={{ clientSecret, customerSessionClientSecret }}
          >
            <StripeCheckoutForm type={amount === 0 ? "collect" : "confirm"} />
          </Elements>
        </>
      )}
    </>
  );
}

export default StripePayment;
