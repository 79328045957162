import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";

import { InvoicesApi } from "../api/InvoicesApi";
import { TransactionsApi } from "../api/TransactionsApi";

import DocumentPreview from "../components/DocumentPreview";
import PaymentMethodModal from "../components/PaymentMethodModal";
import SelectProvince from "../components/Select/SelectProvince";

export default function InvoiceConfirm() {
  const { businessId, invoiceId, linkId } = useParams();

  const [model, setModel] = useState(null);

  const [customerIsPrivate, setCustomerIsPrivate] = useState(true);
  const [editAllowed, setEditAllowed] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [providers, setProviders] = useState([
    {
      code: 1, // Stripe
      method: 1, // Card
      name: "Stripe",
    },
    {
      code: 2, // ACube
      method: 1, // Transfer
      name: "Bonifico Bancario (paga con un link!)",
    },
  ]);

  const handleOnLoad = async () => {
    const result = await InvoicesApi.getByPublicLinkAsync(
      businessId,
      invoiceId,
      linkId
    );
    if (!result) return;
    setModel(result);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    if (!model) return;

    form.setValues(model.invoice);

    const { allowPublicPageEdit, customerType } = model.invoice;
    const isPrivate = customerType === 1;

    setCustomerIsPrivate(isPrivate);
    setEditAllowed(allowPublicPageEdit);
  }, [model]);

  // il base prevede tutti i campi obbligatori tranne SDI e PEC, che gestisco separatamente a seconda della casistica
  const baseValidationSchema = {
    customerFirstName: Yup.string().required("Nome obbligatorio"),
    customerLastName: Yup.string().required("Cognome obbligatorio"),
    customerAddress: Yup.string().required("Indirizzo obbligatorio"),
    customerStreetNumber: Yup.string().required("Numero civico obbligatorio"),
    customerCity: Yup.string().required("Città obbligatoria"),
    customerZipCode: Yup.string().required("CAP obbligatorio"),
    customerProvince: Yup.string().required("Provincia obbligatoria"),
    customerEmail: Yup.string()
      .required("Email obbligatoria")
      .email("Email non valida"),
  };

  const getValidationSchema = () => {
    // se Privato, CF é obbligatorio
    if (customerIsPrivate) {
      return Yup.object({
        ...baseValidationSchema,
        customerGovernmentId: Yup.string().required(
          "Codice Fiscale obbligatorio"
        ),
      });
    }

    // se Business, PEC diventa obbligatoria in caso manchi lo SDI
    return Yup.object({
      ...baseValidationSchema,
      customerVatNumber: Yup.string().required("P.IVA obbligatoria"),
      customerBusinessName: Yup.string().required(
        "Ragione Sociale obbligatoria"
      ),
      customerCertifiedEmail: Yup.string().when(
        "customerEInvoiceProviderCode",
        {
          is: (code) => !code || code.length === 0,
          then: () =>
            Yup.string()
              .required(
                "E' obbligatorio inserire una PEC o il Codice Destinatario"
              )
              .email("PEC non valida"),
          otherwise: () => Yup.string().nullable(true), // Aggiunto per gestire il caso "otherwise"
        }
      ),
    });
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerVatNumber: "",
      customerEInvoiceProviderCode: "",
      customerBusinessName: "",
      customerFirstName: "",
      customerLastName: "",
      customerCertifiedEmail: "",
      customerEmail: "",
      customerPhone: "",
      customerAddress: "",
      customerStreetNumber: "",
      customerCity: "",
      customerZipCode: "",
      customerProvince: "",
    },
    validationSchema: getValidationSchema(),
    onSubmit: () => handleSubmit,
  });

  const handleSubmit = async () => {
    try {
      const errors = await form.validateForm();

      if (Object.keys(errors).length) {
        console.log("form is not valid");
        return;
      }

      setShowModal(true);
    } catch {
      alert("C'è stato un errore durante il salvataggio");
    }
  };

  const handleConfirm = async function (provider, card) {
    let obj = model;
    obj.invoice = form.values;
    obj.invoice.paymentProvider = provider; //1. Stripe, 2. ACube
    obj.invoice.paymentMethod = provider === 1 ? 1 : 0; //1. Card, 2. Transfer
    obj.invoice.eTag = undefined;
    obj.rows = obj.rows.map((items) => ({ ...items, eTag: undefined }));

    const { rowKey } = await InvoicesApi.acceptAsync(
      businessId,
      invoiceId,
      linkId,
      obj
    );

    const result = await TransactionsApi.startAsync(
      businessId,
      rowKey, // transactionId
      {
        paymentProvider: provider, //1. Stripe, 2. ACube
        paymentMethod: provider === 1 ? 1 : 2, //1. Card, 2. Transfer
        description: "Pagamento Fattura " + obj.invoice.code,
      }
    );

    return result;
  };

  return (
    !!model && (
      <>
        <PaymentMethodModal
          amount={model.invoice.totalAmountGross}
          currency={model.invoice.currency}
          providers={providers}
          isVisible={showModal}
          showConditions={true}
          onCancel={() => setShowModal(false)}
          onSubmit={handleConfirm}
        />
        <div className="auth-body-bg">
          <div className="container-fluid p-0">
            <Row className="g-0">
              <div className="col-xxl-8">
                <div className="auth-full-bg pt-lg p-4">
                  <div className="w-100">
                    <div className="bg-overlay" />
                    <div className="d-flex h-100 flex-column">
                      <DocumentPreview model={model} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4">
                <div className="auth-full-page-content p-3">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <Form onSubmit={form.handleSubmit}>
                        <div className="card">
                          <div className="card-body">
                            <Container fluid>
                              <fieldset>
                                <Row>
                                  <Col xs={12}>
                                    <h4 className="card-title mb-4">
                                      Dati di fatturazione
                                    </h4>
                                    <Row>
                                      {customerIsPrivate ? (
                                        <>
                                          <Col xs={12}>
                                            <FormGroup>
                                              <Label for="customerGovernmentId">
                                                Codice Fiscale
                                              </Label>
                                              <Input
                                                id="customerGovernmentId"
                                                name="customerGovernmentId"
                                                type="text"
                                                className="mt-0"
                                                placeholder="Inserire il codice fiscale"
                                                disabled={
                                                  !editAllowed &&
                                                  model.invoice
                                                    .customerGovernmentId
                                                }
                                                value={
                                                  form.values
                                                    .customerGovernmentId || ""
                                                }
                                                onBlur={form.handleBlur}
                                                invalid={
                                                  form.touched
                                                    .customerGovernmentId &&
                                                  form.errors
                                                    .customerGovernmentId
                                                }
                                                onChange={form.handleChange}
                                              ></Input>
                                              {form.touched
                                                .customerGovernmentId &&
                                                form.errors
                                                  .customerGovernmentId && (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      form.errors
                                                        .customerGovernmentId
                                                    }
                                                  </FormFeedback>
                                                )}
                                            </FormGroup>
                                          </Col>
                                        </>
                                      ) : (
                                        <>
                                          <Col xs={12} sm={6}>
                                            <FormGroup>
                                              <Label for="customerVatNumber">
                                                P.IVA
                                              </Label>
                                              <Input
                                                id="customerVatNumber"
                                                name="customerVatNumber"
                                                type="text"
                                                className="mt-0"
                                                placeholder="Inserire P.IVA per la validazione automatica"
                                                disabled={
                                                  !editAllowed &&
                                                  model.invoice
                                                    .customerVatNumber
                                                }
                                                value={
                                                  form.values
                                                    .customerVatNumber || ""
                                                }
                                                onBlur={form.handleBlur}
                                                invalid={
                                                  form.touched
                                                    .customerVatNumber &&
                                                  form.errors.customerVatNumber
                                                }
                                                onChange={form.handleChange}
                                              ></Input>
                                              {form.touched.customerVatNumber &&
                                                form.errors
                                                  .customerVatNumber && (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      form.errors
                                                        .customerVatNumber
                                                    }
                                                  </FormFeedback>
                                                )}
                                            </FormGroup>
                                          </Col>
                                          <Col xs={12} sm={6}>
                                            <FormGroup>
                                              <Label for="customerEInvoiceProviderCode">
                                                Codice Destinatario
                                              </Label>
                                              <Input
                                                id="customerEInvoiceProviderCode"
                                                name="customerEInvoiceProviderCode"
                                                type="text"
                                                className="mt-0"
                                                placeholder="Codice per la fatturazione elettronica"
                                                disabled={
                                                  !editAllowed &&
                                                  model.invoice
                                                    .customerEInvoiceProviderCode
                                                }
                                                value={
                                                  form.values
                                                    .customerEInvoiceProviderCode ||
                                                  ""
                                                }
                                                onBlur={form.handleBlur}
                                                invalid={
                                                  form.touched
                                                    .customerEInvoiceProviderCode &&
                                                  form.errors
                                                    .customerEInvoiceProviderCode
                                                }
                                                onChange={form.handleChange}
                                              ></Input>
                                              {form.touched
                                                .customerEInvoiceProviderCode &&
                                                form.errors
                                                  .customerEInvoiceProviderCode && (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      form.errors
                                                        .customerEInvoiceProviderCode
                                                    }
                                                  </FormFeedback>
                                                )}
                                            </FormGroup>
                                          </Col>
                                          <Col xs={12} sm={6}>
                                            <FormGroup>
                                              <Label for="customerBusinessName">
                                                Ragione Sociale
                                              </Label>
                                              <Input
                                                id="customerBusinessName"
                                                name="customerBusinessName"
                                                type="text"
                                                className="mt-0"
                                                placeholder="Inserisci il nome dell'azienda"
                                                disabled={
                                                  !editAllowed &&
                                                  model.invoice
                                                    .customerBusinessName
                                                }
                                                value={
                                                  form.values
                                                    .customerBusinessName || ""
                                                }
                                                onBlur={form.handleBlur}
                                                invalid={
                                                  form.touched
                                                    .customerBusinessName &&
                                                  form.errors
                                                    .customerBusinessName
                                                }
                                                onChange={form.handleChange}
                                              ></Input>
                                              {form.touched
                                                .customerBusinessName &&
                                                form.errors
                                                  .customerBusinessName && (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      form.errors
                                                        .customerBusinessName
                                                    }
                                                  </FormFeedback>
                                                )}
                                            </FormGroup>
                                          </Col>
                                          <Col xs={12} sm={6}>
                                            <FormGroup>
                                              <Label for="customerCertifiedEmail">
                                                PEC
                                              </Label>
                                              <Input
                                                id="customerCertifiedEmail"
                                                name="customerCertifiedEmail"
                                                type="text"
                                                className="mt-0"
                                                placeholder="PEC aziendale per ricezione contratto"
                                                disabled={
                                                  !editAllowed &&
                                                  model.invoice
                                                    .customerCertifiedEmail
                                                }
                                                value={
                                                  form.values
                                                    .customerCertifiedEmail ||
                                                  ""
                                                }
                                                onBlur={form.handleBlur}
                                                invalid={
                                                  form.touched
                                                    .customerCertifiedEmail &&
                                                  form.errors
                                                    .customerCertifiedEmail
                                                }
                                                onChange={form.handleChange}
                                              ></Input>
                                              {form.touched
                                                .customerCertifiedEmail &&
                                                form.errors
                                                  .customerCertifiedEmail && (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      form.errors
                                                        .customerCertifiedEmail
                                                    }
                                                  </FormFeedback>
                                                )}
                                            </FormGroup>
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerFirstName">
                                            Nome
                                          </Label>
                                          <Input
                                            id="customerFirstName"
                                            name="customerFirstName"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Nome"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerFirstName
                                            }
                                            value={
                                              form.values.customerFirstName ||
                                              ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerFirstName &&
                                              form.errors.customerFirstName
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerFirstName &&
                                            form.errors.customerFirstName && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerFirstName}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerLastName">
                                            Cognome
                                          </Label>
                                          <Input
                                            id="customerLastName"
                                            name="customerLastName"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Cognome"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerLastName
                                            }
                                            value={
                                              form.values.customerLastName || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerLastName &&
                                              form.errors.customerLastName
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerLastName &&
                                            form.errors.customerLastName && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerLastName}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerEmail">
                                            Email
                                          </Label>
                                          <Input
                                            id="customerEmail"
                                            name="customerEmail"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Inserire e-mail"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerEmail
                                            }
                                            value={
                                              form.values.customerEmail || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerEmail &&
                                              form.errors.customerEmail
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerEmail &&
                                            form.errors.customerEmail && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerEmail}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerPhone">
                                            Telefono
                                          </Label>
                                          <Input
                                            id="customerPhone"
                                            name="customerPhone"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Inserire il numero di telefono"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerPhone
                                            }
                                            value={
                                              form.values.customerPhone || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerPhone &&
                                              form.errors.customerPhone
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerAddress">
                                            Indirizzo
                                          </Label>
                                          <Input
                                            id="customerAddress"
                                            name="customerAddress"
                                            type="text"
                                            className="mt-0"
                                            placeholder={
                                              customerIsPrivate
                                                ? `Indirizzo`
                                                : `Indirizzo della sede legale`
                                            }
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerAddress
                                            }
                                            value={
                                              form.values.customerAddress || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerAddress &&
                                              form.errors.customerAddress
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerAddress &&
                                            form.errors.customerAddress && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerAddress}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerStreetNumber">
                                            Numero civico
                                          </Label>
                                          <Input
                                            id="customerStreetNumber"
                                            name="customerStreetNumber"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Numero civico"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerStreetNumber
                                            }
                                            value={
                                              form.values
                                                .customerStreetNumber || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched
                                                .customerStreetNumber &&
                                              form.errors.customerStreetNumber
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerStreetNumber &&
                                            form.errors
                                              .customerStreetNumber && (
                                              <FormFeedback type="invalid">
                                                {
                                                  form.errors
                                                    .customerStreetNumber
                                                }
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerCity">
                                            Città
                                          </Label>
                                          <Input
                                            id="customerCity"
                                            name="customerCity"
                                            type="text"
                                            className="mt-0"
                                            placeholder="Città"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerCity
                                            }
                                            value={
                                              form.values.customerCity || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerCity &&
                                              form.errors.customerCity
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerCity &&
                                            form.errors.customerCity && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerCity}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <label
                                            htmlFor="customerProvince"
                                            className="form-label"
                                          >
                                            Provincia
                                          </label>
                                          <SelectProvince
                                            id="customerProvince"
                                            name="customerProvince"
                                            value={
                                              form.values.customerProvince || ""
                                            }
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerProvince
                                            }
                                            onChange={(value) =>
                                              form.setFieldValue(
                                                "customerProvince",
                                                value
                                              )
                                            }
                                            onBlur={() =>
                                              form.setFieldTouched(
                                                "customerProvince",
                                                true
                                              )
                                            }
                                            invalid={
                                              form.touched.customerProvince &&
                                              form.errors.customerProvince
                                            }
                                          />
                                          {form.touched.customerProvince &&
                                            form.errors.customerProvince && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  marginTop: ".5rem",
                                                }}
                                              >
                                                {form.errors.customerProvince}
                                              </div>
                                            )}
                                        </FormGroup>
                                      </Col>
                                      <Col xs={12} sm={6}>
                                        <FormGroup>
                                          <Label for="customerZipCode">
                                            CAP
                                          </Label>
                                          <Input
                                            id="customerZipCode"
                                            name="customerZipCode"
                                            type="text"
                                            className="mt-0"
                                            placeholder="CAP"
                                            disabled={
                                              !editAllowed &&
                                              model.invoice.customerZipCode
                                            }
                                            value={
                                              form.values.customerZipCode || ""
                                            }
                                            onBlur={form.handleBlur}
                                            invalid={
                                              form.touched.customerZipCode &&
                                              form.errors.customerZipCode
                                            }
                                            onChange={form.handleChange}
                                          ></Input>
                                          {form.touched.customerZipCode &&
                                            form.errors.customerZipCode && (
                                              <FormFeedback type="invalid">
                                                {form.errors.customerZipCode}
                                              </FormFeedback>
                                            )}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </fieldset>
                            </Container>
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-body">
                            <Container fluid>
                              <Row>
                                <Col
                                  xs={12}
                                  className="d-flex justify-content-center"
                                >
                                  <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    color="success"
                                    className="mx-2 w-75"
                                  >
                                    <i className="bx bx-check-double"></i>
                                    <span className="ms-2">
                                      Conferma e paga
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          <p className="mb-0">
                            © {new Date().getFullYear()} Design{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            HoloTeam
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </>
    )
  );
}
