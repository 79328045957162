import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { _BaseUtil } from "../utils/_BaseUtil";

import { useReactToPrint } from "react-to-print";

export default function EstimatePreview({ model }) {
  const [documentType, setDocumentType] = useState(null);
  const [document, setDocument] = useState(null);
  const [services, setServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    if (!model) return;
    const { estimate, preInvoice, invoice, rows } = model;

    let document = null;

    if (estimate) {
      document = estimate;
      setDocumentType("Preventivo");
    }

    if (preInvoice) {
      document = preInvoice;
      setDocumentType("Pro Forma");
    }

    if (invoice) {
      document = invoice;
      setDocumentType("Fattura");
    }

    setDocument(document);
    setServices(
      rows
        .filter((x) => x.serviceType === 1)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );
    setSubscriptions(
      rows
        .filter((x) => x.serviceType === 2)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );
    setNotes(document.notes);
  }, [model]);

  const contentToPrintRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => contentToPrintRef.current,
  });

  const handleShare = async () => {
    const paymentUrl = window.location.href;

    if (navigator.share) {
      const shareOptions = {
        title: "Holo - Pagamento " + document.subject,
        text: `Questo è il link per procedere al pagamento: ${document.subject}. <br /><br />Clicca qui per vedere i dettagli: ${paymentUrl}`,
        url: paymentUrl,
      };

      await navigator.share(shareOptions);
    } else {
      await navigator.clipboard.writeText(paymentUrl);
      toast.info("Link di pagamento copiato negli appunti");
    }
  };

  return (
    !!document && (
      <div ref={contentToPrintRef}>
        <div className="card p-3">
          {" "}
          {/* Use padding here and on card-body to have a correct padding on print */}
          <div className="card-body p-0">
            <div className="invoice-title">
              <h4 className="float-end font-size-16">
                {documentType}: #{document.code}
              </h4>
              <div className="mb-4">
                <img
                  src="/assets/images/logos/logo-dark.png"
                  alt="logo"
                  height="20"
                ></img>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-sm-6">
                <address>
                  <strong>Soggetto fatturazione:</strong>
                  <br />
                  {!document.customerVatNumber
                    ? `${document.customerFirstName} ${document.customerLastName}`
                    : document.customerBusinessName}
                  <br />
                  {!document.customerVatNumber
                    ? !!document.customerGovernmentId &&
                      `Codice Fiscale: ${document.customerGovernmentId}`
                    : !!document.customerVatNumber &&
                      `Partita IVA: ${document.customerVatNumber}`}
                  <br />
                  {document.customerAddress
                    ? `Indirizzo: ${document.customerAddress} ${document.customerStreetNumber}, ${document.customerZipCode} ${document.customerCity}`
                    : null}
                  {`${
                    document.customerProvince
                      ? " (" + document.customerProvince + ")"
                      : ""
                  }`}
                </address>
              </div>
              <div className="col-sm-6 text-sm-end">
                <div>
                  <address>
                    {/* <strong>Prestatore:</strong> */}
                    {/* <br /> */}
                    {document.businessBusinessName}
                    <br />
                    {document.businessVatNumber}
                    <br />
                    {document.businessAddress
                      ? `${document.businessAddress} ${document.businessStreetNumber}, ${document.businessZipCode} ${document.businessCity}`
                      : null}
                    {`${
                      document.businessProvince
                        ? " (" + document.businessProvince + ")"
                        : ""
                    }`}
                  </address>
                </div>
                <address className="mt-2 mt-sm-0">
                  <div className="my-1">
                    <strong>Oggetto:</strong>
                    <br />
                    {document.subject}
                  </div>
                  {!!document.date && (
                    <div className="my-1">
                      <strong>Data:</strong>
                      <br />
                      {_BaseUtil.formatDate(document.date, "it-IT")}
                    </div>
                  )}
                  {!!document.expirationDate && (
                    <div className="my-1">
                      <strong>Data Scadenza:</strong>
                      <br />
                      {_BaseUtil.formatDate(document.expirationDate, "it-IT")}
                    </div>
                  )}
                  {!!document.closingDays && (
                    <div className="my-1">
                      <strong>Giorni consegna:</strong>
                      <br />
                      {document.closingDays}
                    </div>
                  )}
                </address>
              </div>
            </div>
            {!!services.length && (
              <>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 fw-bold">Servizi una tantum</h3>
                </div>
                <div className="table-responsive">
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th style={{ width: "70px" }}>No.</th>
                        <th>Servizio</th>
                        <th className="text-end" style={{ width: "100px" }}>
                          Quantità
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          Prezzo netto
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          IVA
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          Prezzo lordo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {services.map((x, i) => {
                        return (
                          <tr key={i}>
                            {`${x.lineNumber || i + 1}`.padStart(2, "0")}
                            <td>
                              <div>
                                {x.serviceName}
                                <br />
                                <small>{x.serviceDescription}</small>
                              </div>
                            </td>
                            <td className="text-end">{x.quantity}</td>
                            <td className="text-end">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalAmountNet,
                                "it-IT"
                              )}
                            </td>
                            <td className="text-end">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalVatAmount,
                                "it-IT"
                              )}
                            </td>
                            <td className="text-end fw-bold">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalAmountGross,
                                "it-IT"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-end fw-bold">
                          TOTALI&nbsp;&nbsp;&nbsp;
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalAmountNet ||
                              document.totalAmountServicesNet,
                            "it-IT"
                          )}
                        </td>
                        <td className="text-end fw-bold">
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalVatAmount ||
                              document.totalVatAmountServices,
                            "it-IT"
                          )}
                        </td>
                        <td className="text-end fw-bold">
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalAmountGross ||
                              document.totalAmountServicesGross,
                            "it-IT"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {!!subscriptions.length && (
              <>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 fw-bold">Piani ricorrenti</h3>
                </div>
                <div className="table-responsive">
                  <table className="table table-nowrap">
                    <thead>
                      <tr>
                        <th style={{ width: "70px" }}>No.</th>
                        <th>Piano</th>
                        <th className="text-end" style={{ width: "100px" }}>
                          Quantità
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          Prezzo netto
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          IVA
                        </th>
                        <th className="text-end" style={{ width: "150px" }}>
                          Prezzo lordo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map((x, i) => {
                        return (
                          <tr key={i}>
                            {`${x.lineNumber || i + 1}`.padStart(2, "0")}
                            <td>
                              {x.serviceName}
                              <br />
                              <small>{x.serviceDescription}</small>
                              <br />
                              <strong>Durata del piano:</strong>{" "}
                              {x.billingIntervalsCount} mesi
                              <br />
                              <strong>Fatturazione:</strong> mensile anticipata
                              il primo del mese di competenza
                              <br />
                              <strong>Inizio del piano:</strong>{" "}
                              {_BaseUtil.formatDate(x.startDate, "it-IT")}
                            </td>
                            <td className="text-end">{x.quantity}</td>
                            <td className="text-end">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalAmountNet,
                                "it-IT"
                              )}
                            </td>
                            <td className="text-end">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalVatAmount,
                                "it-IT"
                              )}
                            </td>
                            <td className="text-end fw-bold">
                              {_BaseUtil.formatCurrency(
                                x.currency,
                                x.totalAmountGross,
                                "it-IT"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-end fw-bold">
                          TOTALI&nbsp;&nbsp;&nbsp;
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalAmountPlansNet,
                            "it-IT"
                          )}
                        </td>
                        <td className="text-end fw-bold">
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalVatAmountPlans,
                            "it-IT"
                          )}
                        </td>
                        <td className="text-end fw-bold">
                          {_BaseUtil.formatCurrency(
                            document.currency,
                            document.totalAmountPlansGross,
                            "it-IT"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {!!notes && (
              <>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 fw-bold mb-0">
                    Note contrattuali
                  </h3>
                  {notes}
                </div>
              </>
            )}
          </div>
          <div className="d-print-none my-3 mx-3">
            <div className="float-end">
              <button
                type="button"
                className="btn btn-warning waves-effect btn-label waves-light mx-1"
                onClick={handlePrint}
              >
                <i className="fa fa-print label-icon "></i> Stampa
              </button>
              <button
                type="button"
                className="btn btn-light waves-effect btn-label waves-light mx-1"
                onClick={handleShare}
              >
                <i className="bx bx-share label-icon "></i> Condividi
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
