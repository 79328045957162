import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

export default function Revision() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div class="container-fluid p-0">
        <div class="row g-0">
          <div class="col-xl-12">
            <div class="auth-full-bg pt-lg-5 p-4">
              <div class="w-100">
                <div class="bg-overlay"></div>
                <div class="d-flex h-100 flex-column align-items-center">
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-body border-bottom">
                        <div class="avatar-lg me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                          <span class="avatar-title rounded-circle bg-success bg-soft text-success display-3">
                            <i class="bx bx-check"></i>
                          </span>
                        </div>
                        <h1 class="display-4 text-center">
                          Richiesta di revisione inviata
                        </h1>
                        {/* <h3 class="text-muted text-center">
                            Il pagamento di € 200,00 è andato a buon fine
                          </h3> */}
                      </div>
                      {/* <div class="card-body border-bottom">
                          <h3 class="mx-4 mb-4">Prossimi eventi</h3>
                          <ul class="verti-timeline list-unstyled mx-4">
                            <li class="event-list active pb-4">
                              <div class="event-timeline-dot pb-4">
                                <i class="bx bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
                              </div>
                              <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                  <h5 class="font-size-14">
                                    Entro 15 minuti{" "}
                                    <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div class="flex-grow-1">
                                  <div>
                                    Riceverai conferma via PEC dell'ordine
                                    sottoscritto tramite il sistema{" "}
                                    <a
                                      href="https://www.getholo.finance"
                                      target="_blank"
                                      previewlistener="true"
                                    >
                                      HOLO
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="event-list pb-4">
                              <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                              </div>
                              <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                  <h5 class="font-size-14">
                                    Entro 7 giorni{" "}
                                    <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div class="flex-grow-1">
                                  <div>
                                    Confermato il buon esito del pagamento,
                                    riceverai{" "}
                                    <strong>fattura elettronica</strong>{" "}
                                    relativa all'anticipo dell'ordine. La
                                    fattura sarà inviata tramite SDI al codice
                                    destinatario XXXXXX
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="event-list pb-4">
                              <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                              </div>
                              <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                  <h5 class="font-size-14">
                                    01/03/2024{" "}
                                    <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div class="flex-grow-1">
                                  <div>
                                    Inizierà il piano Web Hosting sottoscritto.
                                    Il pagamento avverrà{" "}
                                    <strong>sulla carta preautorizzata</strong>,
                                    a pagamento verificato sarà generata la
                                    fattura elettronica.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="event-list pb-4">
                              <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                              </div>
                              <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                  <h5 class="font-size-14">
                                    30/09/2024{" "}
                                    <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div class="flex-grow-1">
                                  <div>
                                    E' prevista la consegna dell'ordine.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="event-list pb-4">
                              <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                              </div>
                              <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                  <h5 class="font-size-14">
                                    31/12/2024{" "}
                                    <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div class="flex-grow-1">
                                  <div>
                                    E' il termine per disdire il rinnovo
                                    automatico del piano Web Hosting.{" "}
                                    <a href="#">
                                      Aggiungi promemoria a calendario
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div> */}
                      {/* <div class="card-body border-bottom">
                          <div class="row w-100 align-items-center">
                            <div class="col-10">
                              <h5 class="text-muted m-0">
                                Riferimenti: Ordine nr #1/2024 - Id Transazione:
                                9320092309
                              </h5>
                            </div>
                            <div class="col-2 text-end">
                              <button
                                type="button"
                                class="btn btn-warning waves-effect btn-label waves-light"
                              >
                                <i class="bx bx-printer label-icon"></i> Stampa
                              </button>
                            </div>
                          </div>
                        </div> */}
                      <div class="card-body text-center">
                        <h5 class="text-muted">
                          Tramite HOLO è tutto più facile,{" "}
                          <a href="" previewlistener="true">
                            consiglialo a un amico!
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 mt-md-5 text-center">
                    <p class="mb-0">
                      ©{" "}
                      <script>document.write(new Date().getFullYear())</script>
                      2024 Design <i class="mdi mdi-heart text-danger"></i> by
                      HOLO
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
