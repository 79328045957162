// styles
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.min.css";

// Skote CSS
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/app.min.css";

import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/DatePicker.css"; // Import the CSS file you created

import "./assets/css/holo.css";

import "./styles/index.css";

// react
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

console.log("Redirect " + process.env.REACT_APP_REDIRECT_URL);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(<App />);
