export const _BaseUtil = {
    regex_PIVA: /^[0-9]{11}$/,

    copyToClipboard: function(value, callback) {
        console.log("copyToClipboard", value);
        navigator.clipboard.writeText(value).then(callback);
    },

    getImgPath: function(path) {
        if (!path) return "/assets/images/avatar-empty.png";
        return "https://hsapublicdev.blob.core.windows.net/hpublicassets/" + path;
    },

    countBy: function(array, prop, value) {
        return array.filter((obj) => obj[prop] === value).length;
    },

    formatDecimals: function(num) {
        // Round the number to two decimal places
        const roundedNum = Math.round(num * 100) / 100;

        // Use the Italian locale for formatting
        let numFormatted = roundedNum.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return numFormatted;
    },

    formatDate: function(dateString, culture) {
        // Step 1: Convert the string to a JavaScript Date object
        const date = new Date(dateString);

        // Step 2: Format the date based on the culture
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };

        return date.toLocaleDateString(culture, options);
    },

    formatCurrency: function(currency, amount, culture) {
        try {
            const formattedAmount = new Intl.NumberFormat(culture, {
                    style: "currency",
                    currency: currency,
                    currencyDisplay: "code",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
                .format(amount)
                .replace(currency, "")
                .trim();
            return `${currency} ${formattedAmount}`;
        } catch (error) {
            console.error("Currency formatting error:", error);
            return `${currency} ${amount}`;
        }
    },

    validatePIva: function(pIva) {
        return this.regex_PIVA.test(pIva);
    },

    getFullAddress: function(address, streetNumber, zipCode, city, country) {
        if (!address) return "Indirizzo sconosciuto";
        let fullAddres = `${address} ${streetNumber || ""}, ${city || ""} ${
      zipCode || ""
    } ${country || "Italia"}`;

        console.log("getFullAddress result: ", fullAddres);
        return fullAddres;
    },

    getCurrency: function(currencyCode) {
        switch (currencyCode) {
            case "EUR":
                return "€";
            default:
                return "";
        }
    },

    getNextAvailableCode: function(array, attribute) {
        // Extract the numeric portion of each code and store it in an array
        const codeNumbers = array.map((obj) => {
            const numericPart = obj[attribute].match(/\d+/);
            return numericPart ? parseInt(numericPart[0]) : 0;
        });

        // Find the maximum numeric value
        const maxCodeNumber = Math.max(...codeNumbers);

        // Generate the next code by incrementing the maximum numeric value
        const nextCodeNumber = maxCodeNumber + 1;

        return nextCodeNumber;
    },

    tagsToList: function(tagsField) {
        return tagsField ? tagsField.split("|") : [];
    },

    listToTags: function(tagsList) {
        return tagsList ? tagsList.join("|") : "";
    },

    filterAttributes: function(x, filtro) {
        // Concatena tutti gli attributi del servizio in una stringa e trasforma tutto in minuscolo per una ricerca case-insensitive
        const attrs = Object.values(x).join("").toLowerCase();
        return attrs.includes(filtro.toLowerCase());
    },

    downloadPdf: function(strBase64) {
        const base64Data = `data:application/pdf;base64,${strBase64}`;

        // Usa fetch per convertire i dati Base64 in un Blob
        fetch(base64Data)
            .then((res) => res.blob())
            .then((blob) => {
                // Crea un URL dal Blob
                const url = window.URL.createObjectURL(blob);
                // Crea un elemento <a> temporaneo per il download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "contratto.pdf");
                document.body.appendChild(link);
                // Simula un click sul link per avviare il download
                link.click();
                // Rimuove il link dal documento e rilascia l'URL dell'oggetto Blob
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    },
};