import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

export default function Completion() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [setupIntent, setSetupIntent] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [redirectStatus, setRedirectStatus] = useState(null);

  const handleOnLoad = async () => {
    const setupIntent = searchParams.get("setup_intent");
    const paymentIntent = searchParams.get("payment_intent");
    const redirectStatus = searchParams.get("redirect_status");

    setSetupIntent(setupIntent);
    setPaymentIntent(paymentIntent);
    setRedirectStatus(redirectStatus);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  return (
    !!redirectStatus && (
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col>
            <div className="auth-full-bg pt-lg-5 p-4">
              <div className="w-100">
                <div className="bg-overlay"></div>
                <div className="d-flex h-100 flex-column align-items-center">
                  <Col lg={6}>
                    <div className="card">
                      <div className="card-body border-bottom">
                        <div className="avatar-lg me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                          <span className="avatar-title rounded-circle bg-success bg-soft text-success display-3">
                            <i className="bx bx-check"></i>
                          </span>
                        </div>
                        <h1 className="display-4 text-center">
                          Ordine confermato
                        </h1>
                      </div>
                      <div className="card-body text-center">
                        <h5 className="text-muted">
                          Tramite HOLO è tutto più facile,{" "}
                          <a
                            href="https://www.getholo.finance"
                            previewlistener="true"
                          >
                            consiglialo a un amico!
                          </a>
                        </h5>
                      </div>
                    </div>
                  </Col>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      ©{" "}
                      <script>document.write(new Date().getFullYear())</script>
                      2024 Design <i className="mdi mdi-heart text-danger"></i>{" "}
                      by HoloTeam
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
}
