import axios from "axios";
import axiosRetry from "axios-retry";

const client = axios.create({
  headers: {
    "Api-Version": "1.0",
  },
  baseURL: `${process.env.REACT_APP_BASE_URL}`, // 'https://devenvapi.getholofinance.com/webapp' 'https://h-apim-dev.azure-api.net/webapp'
});
axiosRetry(client, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    const randomNum = Math.random() * 500;
    const retryCalc = retryCount * 2000;
    const finalInterval = Math.floor(retryCalc + randomNum);
    return finalInterval; // time interval between retries
  },
  retryCondition: (axios) => {
    console.log("⛔️ ~ axiosConfigs.js:20 ~ retryCondition:", axios);
    // if retry condition is not specified, by default idempotent requests are retried
    if (
      axios.response.status === 408 &&
      axios.config.method.toLowerCase() === "get"
    )
      return true; //timeout
    if (
      axios.response.status === 429 &&
      axios.config.method.toLowerCase() === "get"
    )
      return true; //too many requests
    if (axios.response.status === 503) return true; //service unavailable
    return false;
  },
});
export const api = client;
