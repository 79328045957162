import { _BaseUtil } from "../utils/_BaseUtil";

export const getBadgeClass = (condition, maxDiscountPercentage) => {
  if (!condition.discountPercentage) return ""; // Nessun discountPercentage
  return condition.discountPercentage === maxDiscountPercentage
    ? "badge badge-soft-success"
    : "badge-soft-warning";
};

export default function EstimateConfirmConditionItem({
  index,
  condition,
  checked,
  onClick,
  badgeClass,
}) {
  return (
    <div className="my-1 d-flex align-items-center form-check">
      <input
        id={`condition-${index}`}
        name={`condition-${index}`}
        className="form-check-input"
        type="radio"
        checked={checked}
        onClick={onClick}
      />
      <label htmlFor={`condition-${index}`} className="ms-2 form-check-label">
        {condition.name}
        {!!condition.discountPercentage && condition.discountPercentage > 0 && (
          <>
            <span className={`${badgeClass} font-size-12 ms-3`}>
              Sconto {condition.discountPercentage}% (
              {_BaseUtil.formatCurrency(
                condition.currency,
                condition.discountAmountGross,
                "it-IT"
              )}
              )
            </span>
          </>
        )}
      </label>
    </div>
  );
}
