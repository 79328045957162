import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const LinksCard = ({ key, links }) => {
  const getItemContent = (item) => {
    return (
      <>
        <label className="ms-2 form-check-label">{item.description}:</label>
        <Link to={item.url} target="_blank" className="ms-1">
          {item.url}
        </Link>
      </>
    );
  };

  return (
    !!links?.length && (
      <div className="card">
        <div className="card-body">
          <Row>
            <Col xs={12}>
              <h4 className="card-title my-4">Link</h4>
            </Col>
            {links.map((x, i) => {
              return (
                <Col key={`${key}-${i}`} xs={12} className="my-1">
                  {getItemContent(x)}
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    )
  );
};

export default LinksCard;
