import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ModalTitle } from "react-bootstrap";
import StripePayment from "./StripePayment";
import SepaPayment from "./SepaPayment";
import clsx from "clsx";

import { _BaseUtil } from "../utils/_BaseUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";

export default function PaymentMethodModal({
  amount,
  currency,
  cards,
  providers,
  plans,
  isVisible,
  showConditions,
  onCancel,
  onSubmit,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      icon: "fab fa-cc-mastercard",
      provider: 1,
      title: "Stripe",
      content: (
        <StripePayment
          amount={amount}
          currency={currency}
          cards={cards}
          onSubmit={(card) => onSubmit(1, card)}
        />
      ),
    },
    // {
    //   icon: "fa-solid fa-building-columns",
    //   provider: 2,
    //   title: "Bonifico Bancario",
    //   content: <SepaPayment onSubmit={() => onSubmit(2)} />,
    // },
  ];

  return (
    <Modal isOpen={isVisible} size="lg" className="payment-modal">
      <ModalHeader>
        <ModalTitle>Scegli il metodo di pagamento</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <h4 className="card-title">
          Totale da pagare:{" "}
          {_BaseUtil.formatCurrency(currency, amount, "it-IT")}
        </h4>
        <h6 className="card-title-desc">Scegli il tipo di pagamento</h6>
        <ul className="nav nav-tabs">
          {tabs
            .filter((x) => providers.map((x) => x.code).includes(x.provider))
            .map((x, i) => {
              return (
                <li key={i} className="nav-item">
                  <Link
                    className={clsx(["nav-link", { active: activeTab === i }])}
                    onClick={() => setActiveTab(i)}
                  >
                    <span>
                      <i
                        className={clsx(
                          "me-1",
                          "font-size-20",
                          "align-top",
                          x.icon
                        )}
                      ></i>
                      {x.title}
                    </span>
                  </Link>
                </li>
              );
            })}
        </ul>
        {tabs.map((x, i) => {
          return (
            <div
              key={i}
              className={clsx("tab-content", "p-3", "text-muted", {
                "d-none": activeTab !== i,
              })}
            >
              <div className="p-4 border text-center">{x.content}</div>
            </div>
          );
        })}

        <p className="card-title mt-3">
          Cliccare conferma per procedere al pagamento di{" "}
          {_BaseUtil.formatCurrency(currency, amount, "it-IT")}
        </p>

        {!!plans?.length && (
          <>
            <p className="card-title mt-3">
              {plans.map((x, i) => {
                return (
                  <p className="card-title mt-3" key={i}>
                    Per l'erogazione del piano: {x.name}
                    <br />
                    <u>stai autorizzando l'addebito automatico</u> di{" "}
                    {_BaseUtil.formatCurrency(
                      x.currency,
                      x.totalAmount,
                      "it-IT"
                    )}{" "}
                    che verrà suddiviso in {x.intervals} rate a partire dal{" "}
                    {x.startDate
                      ? _BaseUtil.formatDate(x.startDate, "it-IT")
                      : EstimatesUtil.getNextMonthFirstDayDate().toLocaleDateString()}
                  </p>
                );
              })}
            </p>
          </>
        )}
        {!!showConditions && (
          <p className="mt-3">
            Sulla PEC aziendale indicata riceverai le condizioni generali di
            contratto e la conferma dell'autorizzazione all'addebito delle rate
            future.
            <a href="auth-confirm-mail.html" target="_blank">
              (template messaggio)
            </a>
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Annulla
        </Button>
      </ModalFooter>
    </Modal>
  );
}
