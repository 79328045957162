import { api } from "../configs/axiosConfigs";

export const EstimatesApi = {
  apiName: "EstimatesApi",
  baseUrl: "/webapp/businesses",

  async acceptAsync(businessId, estimateId, linkId, payload) {
    const fnName = "acceptAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      linkId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/publiclinks/${linkId}/accept`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  async getPublicLinkAsync(businessId, estimateId, linkId) {
    const fnName = "getPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      linkId
    );

    const response = await api.get(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/publiclinks/${linkId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  async revise(businessId, estimateId) {
    const fnName = "revise";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/revise`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
