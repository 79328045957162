import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { WithContext as ReactTags } from "react-tag-input";
import "../assets/css/reactTags.css";
import "../assets/css/holo.css";
import { Button, Input, Container, Row, Col, Label } from "reactstrap";

import { toast } from "react-toastify";

import qrCodeImage from "../assets/images/holo_qrcode.png"; // Replace with the path to your QR code image

import QrReader from "react-qr-scanner";

const payBaseUrl = process.env.REACT_APP_PAY_BASE_URL;

export default function Landing() {
  const [redirectLink, setRedirectLink] = useState("");
  const [qrScanning, setQrScanning] = useState(false);

  const isValidUrl = (url) => {
    console.log("isValidUrl", url);

    // Escape special characters in the domain and create the regex pattern.
    const domainRegexPattern = payBaseUrl.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      "\\$&"
    );

    // Create the regular expression with the dynamic domain.
    const urlPattern = new RegExp(
      `^${domainRegexPattern}/[a-f0-9-]+/[a-f0-9-]+/[a-f0-9-]+$`
    );

    // Test the URL pattern
    const isValidURL = urlPattern.test(url);

    return isValidURL;
  };

  const handleQRCodeScan = () => {
    setQrScanning(true);
  };

  const handleScan = (data) => {
    console.log("handleScan", data?.text);
    if (data?.text) {
      setQrScanning(false);
      setRedirectLink(data?.text);
      redirectToLink(data?.text);
    }
  };

  const handleError = (error) => {
    toast.error(error, { autoClose: 10000 });
  };

  const handleLinkInput = (event) => {
    // Update the redirectLink state with the input value.
    setRedirectLink(event.target.value);
  };

  const redirectToLink = (link) => {
    if (!isValidUrl(link)) {
      handleError("Url non valido");
      return;
    }
    window.location.href = link; // Redirect the user to the provided link.
  };

  return (
    <Container fluid className="App text-center py-5">
      <Row className="my-3 d-flex justify-content-center">
        <Col xs={12} sm={6} md={3}>
          <img src="/assets/images/logos/logo-holo.svg" alt="logo" />
        </Col>
      </Row>
      <Row className="my-3 d-flex justify-content-center mt-5">
        <Col xs={12} sm={6} md={3}>
          <h1>Procedi al pagamento</h1>
        </Col>
      </Row>
      <Row className="my-3 d-flex justify-content-center">
        {/* <Col lg={6} className="my-3 my-lg-none">
          <Label htmlFor="btn-qrcode">
            Clicca il pulsante per scansionare il QR code che ti è stato fornito
          </Label>
          <br />
          {qrScanning ? (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          ) : (
            <Button
              id="btn-qrcode"
              className="no-background"
              color="warning"
              onClick={handleQRCodeScan}
            >
              <img src={qrCodeImage} alt="QR Code" width="150" height="150" />
            </Button>
          )}
        </Col> */}
        <Col xs={12} sm={6} md={3}>
          <Label htmlFor="input-link">Inserisci il link</Label>
          <Input
            id="input-link"
            type="text"
            placeholder="Link della pagina di pagamento"
            value={redirectLink}
            onChange={handleLinkInput}
            className="w-md-25"
          />
          <Button color="warning" onClick={() => redirectToLink(redirectLink)}>
            Vai!
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
